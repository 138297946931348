@import "../../variables";
@import "../../functions";

.ItemCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px;
  flex: 1 0 30%; /* explanation below */
  max-width: 650px;
  .p-break {
    word-break: break-all;
  }

  .star1 {
    color: $darkGrey;
  }

  .star2 {
    color: $darkGrey;
  }

  .header {
    .div1 {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .icon2 {
        color: $darkGrey;
        font-size: 18px;
      }

      .icon2:hover {
        cursor: pointer;
        transform: scale(1.2);
        -webkit-transition: transform 0.2s ease-out !important;
        -moz-transition: transform 0.2s ease-out !important;
        -o-transition: transform 0.2s ease-out !important;
        transition: transform 0.2s ease-out !important;
      }

      strong {
        font-size: 16px;
      }

      .deleteDiv {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        column-gap: 15px;
      }

      .iconButtons {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
      }
    }
    .div2 {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    span {
      white-space: nowrap;
      font-size: 11px;
      display: block;
    }

    .left {
      span {
        text-align: left;
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  p {
    margin: 0;
  }

  .p-cardInfo {
    white-space: pre-line;
    font-size: 12px;
    word-break: break-all;
    padding: 3px;
  }

  .p-title {
    font-size: 28px;
    font-weight: bold;
    padding: 5px 0px;
    word-break: break-all;
  }

  .p-subtitle1 {
    font-size: 24px;
    text-decoration: underline;
    margin-bottom: 5px;
  }

  .badge-grey {
    background-color: $lightGrey;
    padding: 2px 5px;
    border-radius: 1rem;
  }

  .boxes {
    padding: 15px 0px;
    flex: 1;

    display: flex;
    align-items: stretch;
    justify-content: space-between;
    column-gap: 10px;
  }

  .date {
    border: 2px solid $lightGrey;
    border-radius: 1rem;
    .p3 {
      font-size: 16px;
      font-weight: bolder;
      margin: 15px 0px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .box {
    min-height: 100px;
    flex: 1;

    border: 2px solid $border2;
    border-radius: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px;
    position: relative;
    margin: 5px 0px;

    .badge {
      position: absolute;
      top: 5px;
      left: 5px;
    }
  }

  .date {
    flex: 1;
  }

  .desc {
    flex: 1;

    textarea {
      border: none;
      resize: none;
      height: 100%;
    }
  }

  .dot {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: $lightGrey;
  }

  .dotActive {
    @extend .dot;
    background-color: $darkGrey;
  }

  .dotLime {
    @extend .dot;
    background-color: $status4;
  }
  .dotPink {
    @extend .dot;
    background-color: $pink;
  }
  .usersIn {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-size: 12px;
  }

  .users {
    span {
      font-size: 11px;
    }

    .grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 20px;
    }
  }

  .footer {
    border-top: 1px solid $darkGrey;
    padding: 10px;

    .dots {
      display: flex;
      align-items: baseline;
      justify-content: center;
      column-gap: 25px;
    }

    .alerts {
      margin-top: 10px;
      display: flex;
      column-gap: 5px;
      row-gap: 5px;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      .badge {
        white-space: pre-line;
      }
    }
    .buttons {
      margin-top: 10px;
      display: flex;
      align-items: stretch;
      justify-content: space-evenly;
      column-gap: 15px;

      a {
        flex: 1;
      }
    }

    .buttonsCust {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  }

  .alert-deadline {
    color: $pink;
  }
  .success-deadline {
    color: $status4;
  }

  .numbers {
    display: flex;
    justify-content: space-evenly;
    padding: 10px 0px;
  }

  @include maxMd {
    .header {
      flex-direction: column;
    }

    .dot {
      height: 15px;
      width: 15px;
    }

    .div2 {
      flex-wrap: wrap;
    }

    .footer {
      .buttons {
        a {
          font-size: 12px;
        }
      }
    }
  }

  @include maxMd {
    .header {
      .div1 {
        flex-direction: column;
        row-gap: 5px;

        .iconButtons {
          justify-content: space-between;
          width: 100%;
        }

        .deleteDiv {
          justify-content: space-between;
          width: 100%;

        }
      }
    }
  }

  @include maxXs {
    .boxes {
      flex-direction: column;
    }

    .header {
      .div2 {
        flex-direction: column;
        row-gap: 10px;

        .right {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
        }
      }
    }

    .footer {
      .buttons {
        flex-direction: column;
        row-gap: 15px;
      }
    }

    .dot {
      height: 10px;
      width: 10px;
    }
  }

  .tags {
    flex: 1;
    border: 2px solid $lightGrey;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    row-gap: 10px;
    flex-wrap: wrap;

    .tag {
      background-color: $lightGrey;
      padding: 5px;
      border-radius: 1rem;
    }
  }

  .responsiblesDiv {
    padding: 10px;

    span {
      background-color: $lightGrey;
      padding: 5px;
      border-radius: 1rem;
      font-size: 12px;
      font-weight: bold;
    }

    .rowDiw {
      display: flex;
      column-gap: 15px;
      row-gap: 15px;
      flex-wrap: wrap;
    }
  }

  .todoCard {
    flex: 1;

    display: flex;
    flex-direction: column;
  }

  .todoTitleDiv {
    flex: 1;
    background-color: $lightGrey;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;

    p {
      font-weight: bold;
      font-size: 22px;
      word-break: break-all;
    }
  }

  .todoContent {
    padding: 15px 0;
    flex: 4;
    max-height: 400px;
    overflow: auto;

    textarea:disabled {
      padding-top: 15px;
      background-color: transparent;
      border: none;
    }
    .noResults {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        border: 1px solid $red;
        padding: 25px 15px;
        border-radius: 1rem;
      }
    }

    .active {
      background-color: $lightBlue1;
    }
    .expandedTd {
      background-color: $lightGrey;
    }
  }
}

.badge-status-basic {
  border-radius: 1rem 1rem 0px 0px;
  margin: -5px -5px 0px -5px;
  padding: 2px 5px;
}

.badge-status-0 {
  @extend .badge-status-basic;
  background-color: $status0;
}

.badge-status-1 {
  @extend .badge-status-basic;
  background-color: $status1;
}
.badge-status-2 {
  @extend .badge-status-basic;
  background-color: $status2;
}
.badge-status-3 {
  @extend .badge-status-basic;
  background-color: $status3;
}

.badge-status-4 {
  @extend .badge-status-basic;
  background-color: $status4;
}
.badge-status-5 {
  @extend .badge-status-basic;
  background-color: $status5;
}

.circle-status-basic {
  padding: 2px 4px;
  border-radius: 1rem;
  color: black;
}

.circle-status-0 {
  @extend .circle-status-basic;
  background-color: $status0;
}

.circle-status-1 {
  @extend .circle-status-basic;
  background-color: $status1;
}
.circle-status-2 {
  @extend .circle-status-basic;
  background-color: $status2;
}
.circle-status-3 {
  @extend .circle-status-basic;
  background-color: $status3;
}

.circle-status-4 {
  @extend .circle-status-basic;
  background-color: $status4;
}
.circle-status-5 {
  @extend .circle-status-basic;
  background-color: $status5;
}

.SimpleItemCard-size-1 {
  flex: 1 0 100%;
}
.SimpleItemCard-size-2 {
  flex: 1 0 40%;
}
.SimpleItemCard-size-3 {
  flex: 1 0 28%;
}

.SimpleItemCard {
  display: flex;
  align-items: center;
  justify-content: center;

  .alerts {
    width: 100%;
    text-align: left;

    .badge {
      margin: 0px 2px;
      font-size: 11px;
    }
  }
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $darkGrey;
    padding: 5px 0px;
    border-bottom: 1px solid $lightGrey;

    .dates {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      text-align: left;
    }
  }

  .content {
    width: 100%;
    border-radius: 1rem;
    padding: 10px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    border-radius: 1rem;
    border: 1px solid $darkGrey;
    max-width: 850px;
  }

  .dates2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-top: 5px;
  }

  .p-title {
    margin: 0;
    font-size: 22px;
    white-space: pre-line;
    color: black;
    padding: 15px 0px;
    font-weight: bold;
  }

  .content:hover {
    -webkit-transition: border 1s ease;
    -moz-transition: border 1s ease;
    -o-transition: border 1s ease;
    -ms-transition: border 1s ease;
    transition: border 1s ease;

    border-color: $pink !important;
  }

  @include maxMd {
    .p-title {
      font-size: 18px;
    }
  }

  @include maxSm {
    .p-title {
      font-size: 14px;
    }

    .dates2 {
      flex-direction: column;
    }
  }
}

.Fit {
  .p-title {
    font-size: 22px;
    font-weight: bold;
    padding: 5px 0px;
  }

  .p-subtitle1 {
    font-size: 14px;
    text-decoration: underline;
    margin-bottom: 5px;
  }

  @include maxLg {
    .p-title {
      font-size: 16px;
      font-weight: bold;
      padding: 5px 0px;
    }

    .p-subtitle1 {
      font-size: 14px;
      text-decoration: underline;
      margin-bottom: 5px;
    }
  }

  @include maxMd {
    .div2 {
      flex-direction: column;
      align-items: stretch;
    }

    .left {
      width: 100%;
    }
    .right {
      padding: 15px 0px;
      width: 100%;
      align-items: flex-start !important;
    }
  }
}
//item scss

.blurItem {
  filter: blur(8px);
  pointer-events: "none";
}

.blurItem:hover {
  -webkit-filter: blur(0);
  filter: blur(0);
  transition-property: filter;
  transition-duration: 1000ms;
}

.ItemCard:hover {
  cursor: pointer;
  transform: scale(1.03);
  -webkit-transition: transform 0.2s ease-out !important;
  -moz-transition: transform 0.2s ease-out !important;
  -o-transition: transform 0.2s ease-out !important;
  transition: transform 0.2s ease-out !important;
}

@include maxMd {
  .ItemCard:hover {
    cursor: none;
    transform: none;
  }
}
