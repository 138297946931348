@import "../../variables";
@import "../../functions";

.mappedItems {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  column-gap: 15px;
  row-gap: 15px;
  padding: 25px 0px;
}

h1 {
  color: $pink;
}

.noResult {
  max-width: 650px;
  margin: 25px auto;

  padding: 25px 0px;
  border: 1px solid $pink;
  border-radius: 1rem;

  p {
    font-size: 28px;
  }
}

.LinkUserMain {
  .menu {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    max-width: 650px;
    margin: auto;

    .menuItem {
      margin: 50px 10px;
      background-color: $blue1;
      padding: 20px 0px;
      border-radius: 1rem;
    }
  }

  .menuItem:hover {
    cursor: pointer;
    transform: scale(1.03);
    -webkit-transition: transform 0.2s ease-out !important;
    -moz-transition: transform 0.2s ease-out !important;
    -o-transition: transform 0.2s ease-out !important;
    transition: transform 0.2s ease-out !important;
  }
}
.LinkUserBasicCard {
  width: 100%;
  margin: auto;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 850px;

  .alerts {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    column-gap: 5px;
    row-gap: 5px;
  }

  .content {
    padding: 15px 0px;
  }

  p {
    margin: 0;
    text-align: left;
  }

  .p-desc {
    white-space: pre-line;
    padding: 15px 0px;
    font-size: 12px;
  }
  .p-sub {
    color: $pink;
  }

  .textBox {
    padding: 25px 5px;
  }

  .answerBox {
    margin-top: -25px;
    padding-left: 25px;
    background-color: $lightGrey;
  }

  .footer {
    width: 100%;

    .buttons {
      display: flex;
      align-items: stretch;
      justify-content: space-evenly;
      column-gap: 15px;
      row-gap: 15px;

      button {
        flex: 1;
      }
      a {
        flex: 1;
      }
    }
  }
}

.LinkUserChat {
  p {
    margin: 0;
  }

  .sendForm {
    max-width: 650px;
    margin: 15px auto;

    .form-control {
      min-height: 36px;
    }
  }

  .mappedMessages {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    row-gap: 15px;
  }

  .msgBox {
    min-width: 300px;
    max-width: 80%;
    padding: 5px;
  }
  .otherMsg {
    @extend .msgBox;
    align-self: flex-start;
    border: 2px solid $pink;
  }
  .myMsg {
    @extend .msgBox;
    align-self: flex-end;
    border: 2px solid $blue1;
  }

  .header {
    border-bottom: 1px solid $lightGrey;
    margin-bottom: 5px;
    padding: 5px 0px;
    font-size: 12px;
    text-align: left;
  }

  .content {
    padding: 10px 0px;
    text-align: left;
  }

  .footer {
    border-top: 1px solid $lightGrey;
    margin-top: 5px;
    padding: 5px 0px;
  }
}
