@import "../../variables";
@import "../../functions";

.errorBoundary {
  padding: 20px;
  margin-top: 25px;
  background-color: white;

  h2 {
    background-color: $red;
    margin: -20px;
    padding: 15px 0px;
  }

  .content {
    margin: 50px 0px;
  }

  p {
    margin: 0;
  }

  .thanks {
    margin: 20px -20px -50px -20px;
    padding: 15px;
    background-color: $lime;

    a {
      color: $blue2;
    }
  }
}
