.FilesList {
  padding: 25px 0px;

  .files {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;

    img {
      max-height: 300px;
      max-width: 100%;
      border-radius: 1rem;
    }

    video {
      max-height: 300px;
      max-width: 100%;
      border-radius: 1rem;
    }

    .fileBox {
        flex: 1;
      border: 1px solid black;
      border-radius: 1rem;
      padding: 5px;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      row-gap: 15px;

      img {
        flex: 1;
      }
    }

    .pdfBox {
      p {
        text-align: center;
      }
    }
  }
}
