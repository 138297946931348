@import "../../variables";
@import "../../functions";

.changedContainer {
  position: fixed;
  top: 20px;
  bottom: 20px;
  left: 20px;
  right: 20px;
  background-color: white;
  border-radius: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;

  .mainDiv {
    background-color: $red;
    padding: 25px;
    border-radius: 1rem;
    margin: 5px;
  }

  .p-title {
      font-weight: bold;
      font-size: 22px;
  }
}
