@import "../../variables";
@import "../../functions";

.appAlert {
  z-index: 9999;
  margin: auto;
  width: 100%;
  max-width: 500px;
  p {
    color: black;
    margin: 0;
  }
}

.alert-success {
  background-color: $lime !important;
}

.alert-danger {
  background-color: $red !important;
}

.alert-warning {
  background-color: $yellow !important;
}

.alert-info {
  background-color: $lightBlue !important;
}

.ConfirmDiv {
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .buttons {
    align-self: flex-end;

    button {
      margin: 0px 10px;
    }
  }
}

.info-div {
  background-color: $lightBlue1;
  opacity: 0.7;
}

.warning-div {
  background-color: $yellow;
}
