@import "../../variables";

.customSpinner {
  width: 4rem !important;
  height: 4rem !important;
  margin: 4rem auto !important;
  border-right-color: transparent !important;
}
.customSpinner2 {
  min-height: calc(100vh - 75px);
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 90%;
    max-width: 500px;
    background-color: white;
    border-radius: 1rem;
  }
}

/*
.flipping {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  100% {
    transform: rotatex(360deg);
  }
}
*/
