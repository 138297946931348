@import "../../variables";
@import "../../functions";

.appRegister {
  display: flex;
  flex-direction: column;
  padding: 50px 10px;

  form {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 80%;
    max-width: 400px;

    button {
      margin: 20px auto;
    }
  }

  a {
    margin: 10px auto;
  }
}

.RegisterCompanyAndAdmin {
  position: relative;
  form {
    width: 95%;
    max-width: 850px;
    margin: auto;
  }

  .forms {
    padding: 15px;
  }

  .formUser {
    margin: 50px auto;
  }

  .flex {
    display: flex;
    align-items: baseline;
    justify-content: center;
    column-gap: 10px;
    row-gap: 10px;
  }

  .flex-1 {
    flex: 1;
    padding: 25px 0;
  }
  .flex-2 {
    flex: 2;
  }
  .flex-3 {
    flex: 3;
  }

  .successDivMain {
    position: absolute;
    top: 10px;
    bottom: 10px;
    left: 10px;
    right: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .successDiv {
    width: 90%;
    max-width: 750px;
    padding: 25px 15px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
      white-space: pre-wrap;
    }
    strong {
      white-space: pre-wrap;
      
    }
    .header {
      flex: 1;
      padding: 50px 0;
      background-color: $green;
      width: 100%;
      border-radius: 1rem;
    }
    .content2 {
      flex: 2;
      padding: 50px 0;
      width: 100%;
    }
  }

  @include maxSm {
    .forms {
      padding: 5px;
    }
    .flex {
      flex-direction: column;

      .form-group {
        width: 100%;
      }
    }
  }
}
