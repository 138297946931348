@import "../../variables";

.AutoSize {
  .form-group {
    margin: 25px 0px;
  }
  .customText::-webkit-scrollbar {
    width: 5px;
  }

  .form-text {
    text-align: left;
    margin-bottom: 25px;
    padding: 0;
    color: $lightText1;
  }
}
