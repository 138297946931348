@import "../../variables";
@import "../../functions";

.NoAccess {
  height: calc(100vh - 75px);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 50px 0px;

  .item {
    background-color: $red;
    padding: 25px;
    border-radius: 1rem;
  }

  a {
    color: black;
  }
}
