@import "../../variables";
@import "../../functions";

.navBar {
  background-color: white;
  height: 75px;

  p {
    margin: 0;
  }

  .dropdown-toggle::after {
    display: none;
  }

  .navContainer {
    display: flex !important;
    align-items: center;
    justify-content: space-between;

    padding: 0px 5px;
  }

  .logOut {
    color: $pink;
  }

  .centerDiv {
    img {
      width: 100%;
      max-height: 70px;
      object-fit: cover;
    }
  }
  .leftDiv {
    min-width: 75px;
    text-align: left;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .dates {
      display: flex;
      column-gap: 5px;
      white-space: nowrap;
      padding-right: 5px;
      margin-right: 10px;
    }
  }

  #dropdown-basic-button {
    padding: 0;
  }
  .rightDiv {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  .menuDiv {
    display: flex;
  }

  .circle {
    height: 15px;
    width: 15px;
    border: 1px solid $darkGrey;
    border-radius: 50%;
    background-color: $lime;
  }

  .presentDiv {
    background-color: $lime;
    border-radius: 1rem;
    padding: 5px;

    p {
      font-size: 11px;
      text-align: center;
    }
  }
}

.itemInfoDiv {
  p {
    margin: 0;
    text-align: left;
  }

  background-color: $lightBlue1;
  padding: 5px;

  display: flex;
  align-items: stretch;
  justify-content: space-between;

  .p-title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 22px;
    padding-right: 10px;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;

    margin-bottom: 15px;
  }

  .p-name {
    font-size: 18px;
    margin-bottom: 5px;
  }

  .divRight {
    p {
      text-align: left;
      font-size: 18px;
    }

    .p-date {
      white-space: nowrap;
    }

    span {
      font-size: 18px;
      margin-right: 5px;
    }
  }
}
.itemIconTabs {
  display: flex;
  flex-direction: column;
  height: 40px;
  border-bottom: none !important;

  a {
    height: 40px;
    color: $white !important;
    background-color: $darkGrey !important;
    border: none !important;
    outline: unset;
    border-radius: 0 !important;
  }

  .active {
    color: $gold !important;
    outline-color: $gold;
    border-bottom: 4px solid $gold !important;
  }

  .iconDiv {
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      color: white;
      margin: 0;
      margin-left: 5px;
    }
    .tabIcons {
      color: white;
    }
  }
}

.sticky {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -o-sticky;
  position: -ms-sticky;
  position: sticky;
  top: 75px;
  z-index: 2;
}

.children {
  // height: calc(100vh - 75px);
  //overflow: auto;
}

footer {
  width: calc(100% - 30px);
  margin: auto;
  background-color: white;
  border-radius: 0 0 1rem 1rem;
  padding-top: 25px;

  img {
    width: 100%;

    max-width: 600px;
  }
}
@include maxMd {
  .itemIconTabs {
    a {
      font-size: 15px;
      padding: 4px;
      height: 60px;
    }

    .iconDiv {
      display: block;
    }
  }

  .itemInfoDiv {
    .p-title {
      font-size: 18px;
    }

    .p-name {
      font-size: 16px;
    }
  }
}

@include maxSm {
  .dates {
    // flex-direction: column;
    font-size: 12px;
  }
  .itemIconTabs {
    a {
      font-size: 10px;
      padding: 4px;
      height: 60px;
    }
  }

  .itemInfoDiv {
    flex-direction: column;
  }

  .navBar {
    .rightDiv {
      .buttons {
        .btn {
          padding: 5px;
        }
      }
    }
  }

  .navBar {
    .circle {
      height: 10px;
      width: 10px;
    }
  }

  footer {
    width: calc(100% - 10px);
  }
}

@include maxXs {
  .navBar {
    .leftDiv {
      min-width: unset;
    }
  }
  .itemIconTabs {
    a {
      height: 40px;
      font-size: 15px;
    }

    .iconDiv {
      .iconText {
        display: none;
      }
    }
  }
}
