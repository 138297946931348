@import "../../variables";
@import "../../functions";

.Folders {
  p {
    margin: 0;
  }
  .p-title {
    font-size: 32px;
  }

  .p1 {
    font-size: 18px;
    margin-bottom: 15px;
  }

  .breadcrumb {
    background-color: white;
    margin: 0;
  }

  .breadcrumb-item.active {
    color: $darkGrey;
  }

  .breadcrumb-item {
    a {
      color: $pink;
    }
  }

  .sticky {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -o-sticky;
    position: -ms-sticky;
    position: sticky;
    top: 75px;
    z-index: 2;
    border-bottom: 1px solid $darkGrey;
    margin: -15px -15px 0px -15px;
  }

  .confirmAlert {
    margin: 0px -15px 15px -15px;
  }

  form {
    width: 90%;
    margin: auto;
    max-width: 750px;
  }

  .search {
    max-width: 500px;
  }

  .titleRow {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .iconAdd {
      color: $lime;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      padding: 5px;
    }
  }

  .box {
    padding: 25px 0px;
  }

  .form-control:disabled {
    background-color: transparent;
    border: none;
    text-align: center;
  }

  .gridView {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  .folders {
    @extend .gridView;
  }

  .subFolders {
    @extend .gridView;

    .folderCard {
      margin: 0px 5px;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 1rem 1rem 0 0;
        padding: 5px;
        height: 35px;

        .iconBasic {
          font-size: 25px;
        }

        .cancelIcon {
          @extend .iconBasic;
          color: black;
        }

        .saveIcon {
          @extend .iconBasic;
          color: black;
        }

        .dropDiv {
          width: 100%;
          text-align: right;
        }

        .dropdown-menu {
          background-color: $lightGrey;
        }

        .dropdown-item:hover {
          background-color: $lightBlue1;
        }
      }

      .form-group {
        margin: 5px;
      }

      .info {
        span {
          padding: 0px 5px;
          white-space: nowrap;
          font-size: 11px;
          font-style: italic;
        }
      }

      .primary {
        background-color: green;
      }
      .secondary {
        background-color: yellow;
      }
      .basic {
        background-color: $darkGrey;
      }
    }

    .content {
      border-radius: 0 0 1rem 1rem;
      margin: -0px -5px -5px -5px;
      transition: 0.5s;
      padding: 5px;

      .dates {
        small {
          white-space: nowrap;
          display: block;
          text-align: left;
        }
      }
    }
    .folderIcon {
      font-size: 70px;
      transition: 0.5s;
    }

    .content:hover {
      .folderIcon {
        color: $darkLime;
        transform: scale(1.1);
        -webkit-transition: transform 0.2s ease-out !important;
        -moz-transition: transform 0.2s ease-out !important;
        -o-transition: transform 0.2s ease-out !important;
        transition: transform 0.2s ease-out !important;
      }
    }
  }

  .items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  @include maxLg {
    .gridView {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  @include maxMd {
    .gridView {
      grid-template-columns: 1fr 1fr;
    }

    .items {
      grid-template-columns: repeat(1, 1fr);
    }

    .titleRow {
      .iconAdd {
        height: 50px;
        width: 50px;
      }
    }
  }

  @include maxSm {
    .folderIcon {
      font-size: 40px !important;
    }
  }
}

.CustomToggle {
  .threeDots {
    height: 20px;
    width: 20px;
  }
}
