@import "../../variables";

.DisplayHome {
  padding: 10px;
  .infoDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    p {
      margin: 0;
    }

    .date {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 25px;
      p {
        text-align: center;
        font-size: 22px;
      }

      .p-date {
        color: $pink;
      }
      .p-time {
        color: $pink;
      }
    }
  }
  .dbInfo {
    margin-bottom: 25px;

    .alerts {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      column-gap: 25px;
      row-gap: 15px;
      .badge {
        padding: 10px;
        font-size: 18px;
      }
    }
  }

  .items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 50px;
  }

  .boardCard {
    p {
      margin: 0;
    }
    border-radius: 1rem;
    border: 1px solid $darkGrey;
    width: 90%;

    .dates {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 25px;
      border-radius: 1rem 1rem 0 0;

      p {
        font-size: 28px;
      }
    }

    .status1 {
      background-color: $lime;
    }
    .status2 {
      background-color: $yellow;
    }
    .status3 {
      background-color: $lightPink;
    }

    .p-title {
      font-size: 52px;
      color: $pink;
    }

    .p-desc {
      white-space: pre-line;
      font-size: 26px;
      padding: 10px;
    }
  }

  .emptyScreen {
    border-radius: 1rem;
    border: 1px solid $darkGrey;
    width: 80%;
    max-width: 800px;
    margin: auto;

    img {
      width: 100%;
    }
  }
}
