@import "../../variables";
@import "../../functions";

.CalendarPage {
  background-color: white;

  overflow: auto;

  .calendar {
    height: calc(100vh - 75px);
  }

  .itemEvent {
    background-color: $darkLime;
  }

  .offDayEvent {
    background-color: $darkGrey;
  }
}

.Calendar2 {
  background-color: white;
  padding: 10px;
  .itemEvent {
    background-color: $darkLime;
  }

  .offDayEvent {
    background-color: $darkGrey;
  }

  .calendar {
    border: 1px solid $darkGrey;
    border-radius: 1rem;
    padding: 10px;
    overflow: auto;
    margin-bottom: 25px;

    .cal {
      height: calc(100vh - 75px);
    }
  }

  @include maxLg {
    padding: 5px;

    .calendar {
      padding: 5px;
    }
  }
}

.CalendarNav {
  background-color: $lightGrey;
  padding: 5px;

  .views {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    button {
      flex: 1;
      margin: 0px 5px;
    }
  }

  .navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .titles {
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      p {
        margin: 0px 10px;
      }
    }
  }
}

.Calendar3 {
  background-color: white;
  position: relative;

  .labels {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 15px;

    p {
      margin: 0;
      font-size: 12px;
    }
    .item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      column-gap: 5px;
    }
    .circle {
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
  }

  .rbc-calendar {
    height: calc(100vh - 75px);
  }

  .itemEvent {
    background-color: $darkLime;
  }

  .reportEvent {
    background-color: $lightPink;
    color: black;
  }

  .loadingDiv {
    background-color: rgba(10, 10, 10, 0.8);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 999;

    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  @include maxSm {
    .rbc-calendar {
      height: calc(150vh - 75px);
    }

    .rbc-event {
      font-size: 9px;
    }
  }
}

.PopoverEvent {
  .popover-body {
    background-color: white !important;
    border-radius: 1rem !important;
    .p-date {
      font-size: 12px;
      font-weight: bold;
    }
    .pop-title {
      color: $pink !important;
      font-size: 18px;
    }
    .p-desc {
      margin-top: 15px;
    }
  }
}
