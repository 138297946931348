@import "../../variables";
@import "../../functions";

.MessageBoard {
  p {
    margin: 0;
  }
  .mainHeader {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .filterDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 15px;
  }

  .items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    margin-top: 25px;
  }
  .msgCard {
    border: 1px solid $darkGrey;
    border-radius: 1rem;

    .header {
      padding: 5px;
      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .content {
      padding: 5px;
    }

    .footer {
      padding: 5px;

      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  @include maxSm {
    .items {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.OneMessageBoard {
  .subTitleDiv {
    display: flex;
    align-items: baseline;
    column-gap: 25px;

    h5 {
      color: $lightBlue;
      margin: 0;
    }

    padding: 25px 0px;
    margin-top: 25px;

    .divider {
      flex: 1;
      height: 3px;
      background-color: $lightBlue1;
    }
  }

  p {
    margin: 0;
  }
  .buttons {
    display: flex;
    align-items: center;
    column-gap: 15px;
  }

  .dates {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0px;
    column-gap: 15px;
  }

  .p-desc {
    padding: 15px 0px;
    white-space: pre-line;
  }

  .forms {
    max-width: 800px;
    margin: auto;

    .switches {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px 0px;
      column-gap: 15px;
    }
  }

  .datePickers {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}
