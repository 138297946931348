@import "../../variables";
@import "../../functions";

.Ask {
  position: fixed;
  top: 5px;
  left: 5px;
  z-index: 99999999999999;
  border-radius: 1rem;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 5px;

  .box {
    background-color: white;
    padding: 5px;
    border-radius: 1rem;
    border: 2px solid black;
    max-width: 300px;

    p {
      margin: 0;
      font-size: 12px;
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 15px;
      flex-wrap: wrap;
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 15px;

      button {
        flex: 1;
      }
    }
  }
}

.Answers {
  position: fixed;
  top: 5px;
  left: 5px;
  z-index: 99999999999999;
  border-radius: 1rem;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 5px;

  p {
    margin: 0;
    font-size: 12px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 15px;
    flex-wrap: wrap;
  }

  .clearIcon {
    color: $pink;
    align-self: flex-end;
  }
  .box {
    background-color: white;
    padding: 5px;
    border-radius: 1rem;
    border: 2px solid black;

    .p-yes {
      font-size: 18px;
      color: $darkLime;
    }
    .p-no {
      font-size: 18px;
      color: $pink;
    }
  }
}
