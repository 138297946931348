@import "../../variables";
@import "../../functions";

.Item {
  background-color: white;
  margin: 15px;
  padding: 5px;
  min-height: calc(100vh - 254px);
  border-radius: 1rem;
  padding-bottom: 50px;

  p {
    margin: 0;
  }

  .p1 {
    font-size: 22px;
    font-weight: bold;
  }

  .p2 {
    font-size: 18px;
    font-weight: bolder;
  }

  .p-light {
    color: $lightText1;
  }

  .p-break {
    word-break: break-all;
  }

  .p-cardInfo {
    white-space: pre-line;
  }

  .divider {
    flex-grow: 1;
    border-bottom: 2px solid $lightBlue;
    margin: 25px;
  }

  .circle {
    padding: 20px;

    height: 130px;
    width: 130px;
    border-radius: 50%;
    margin: 0px 5px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 2px solid $blue1;

    position: relative;

    .p-circle {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 120px;
    }
  }
}

.buttonElement:hover {
  cursor: pointer;
  transform: scale(1.02);
  -webkit-transition: transform 0.2s ease-out !important;
  -moz-transition: transform 0.2s ease-out !important;
  -o-transition: transform 0.2s ease-out !important;
  transition: transform 0.2s ease-out !important;
}
.HistoryTab {
  margin-bottom: 50px;
  background-color: white;
  border-radius: 1rem;
  padding: 15px;

  .historyEvent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    padding: 5px;
    width: 90%;
    max-width: 500px;
    border-top: 1px solid $lightBlue;

    p {
      margin: 20px;
      color: $darkGrey;
    }

    .historyDate {
      font-size: 14px;
      color: #989898;
      margin-bottom: 0;
    }
  }
}

.ConsumerItems {
  min-height: calc(100vh - 105px);
  margin: 15px;
  padding: 15px;
  background-color: white;
  border-radius: 1rem;

  .itemsDiv {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  p {
    margin: 0px;
  }

  .p1 {
    font-size: 12px;
    font-weight: bold;
  }

  .p2 {
    font-size: 18px !important;
    font-weight: bolder;
  }

  .titleRow {
    background-color: $lightBlue1;
    margin: -15px -15px 0px -15px;
    padding: 0px 5px;
    border-radius: 1rem 1rem 0px 0px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 25px;

    .address {
      p {
        font-size: 14px;
      }
    }
  }

  .div1 {
    display: flex;
    align-items: stretch;
    justify-content: space-evenly;
  }

  .item1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px 5px;
    border-radius: 1rem;
    padding: 5px;
  }

  .schedule {
    @extend .item1;
    border: 3px solid $pink;

    flex: 1;

    p {
      font-size: 18px;
    }
  }

  .company {
    @extend .item1;
    border: 3px solid $pink;
    flex: 1;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .p-companyName {
      font-size: 28px;
      font-weight: bolder;
    }

    .address {
      border-radius: 1rem;
      padding: 5px 10px;
    }

    .contacts {
      border-radius: 1rem;
      padding: 5px 10px;
      margin-top: 5px;
    }
  }

  .imagesDiv {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  .imageDiv {
    max-width: 90%;
    max-height: 90%;
    margin: 15px auto;
    border: 1px solid $lightGrey;
    padding: 5px;

    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;

    .imageButtons {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        font-size: 12px;
      }
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 0px;

      .iconButton {
        height: 30px;
        width: 30px;
      }
    }

    .img-thumbnail {
      max-height: 100%;
      max-width: 100%;
      border: none;
    }
  }

  .links {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 25px 0px;
    max-width: 100%;
  }
  a {
    text-decoration: none !important;
    color: black;
  }
  .linkButton {
    min-height: 75px;
    min-width: 45%;
    border-radius: 1rem;
    border: 3px solid $border2;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.ImageTab {
  .imageHeaderRow {
    display: flex;
    column-gap: 15px;
    padding: 15px 0;
  }
}
@include maxMd {
  .Item {
    margin: 25px 5px;

    .p1 {
      font-size: 18px;
      font-weight: bold;
    }

    .p2 {
      font-size: 16px;
      font-weight: bolder;
    }
  }

  .ImageTab {
    .files {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .ConsumerItems {
    margin: 15px 5px;
    padding: 5px;

    .titleRow {
      margin: -5px -5px 15px -5px;
    }
    .itemsDiv {
      grid-template-columns: repeat(1, 1fr);
    }

    .company {
      flex-direction: column;
    }
    .imagesDiv {
      grid-template-columns: repeat(2, 1fr);
    }

    .schedule {
      p {
        font-size: 16px;
        white-space: nowrap;
      }
    }
  }
}

@include maxSm {
  .Item {
    margin: 10px 5px;

    .p1 {
      font-size: 16px;
      font-weight: bold;
    }

    .p2 {
      font-size: 14px;
      font-weight: bolder;
    }
  }

  .ImageTab {
    .files {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .ConsumerItems {
    .titleRow {
      flex-direction: column;
    }
    .schedule {
      margin-bottom: 15px;
    }
    .div1 {
      flex-direction: column;
    }

    .imagesDiv {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.ItemChecklists {
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;

    p {
      font-size: 22px;
    }
  }
  .listDiv {
    margin: 25px 0px;
  }

  .deleteIcon {
    color: $pink;
    font-size: 28px;
  }

  .divider {
    height: 3px;
    background-color: $pink;
    margin: 15px auto;

    max-width: 800px;
  }
  .addRowIcon {
    color: $darkLime;
    font-size: 22px;
  }
  .checkCard {
    padding: 25px 10px;

    border: 1px solid $darkGrey;
    border-radius: 1rem;

    max-width: 750px;
    margin: 15px auto;

    .header {
      display: flex;
      align-items: center;
      column-gap: 15px;
      border-bottom: 1px solid $lightBlue1;
      padding-bottom: 5px;
    }

    .form-control:disabled {
      border: none;
      background-color: transparent;
    }
  }

  .notes {
    margin: 10px 0px;
    border-radius: 1rem;
    padding: 0px 10px;
  }

  th {
    background-color: $lightPink;
    font-size: 12px;
  }

  .th-done {
    max-width: 30px;
  }

  .th-delete {
    max-width: 30px;
  }

  td {
    .form-control {
      font-size: 14px;
      padding: 3px 6px;
      text-align: center;
    }
  }

  .td-done {
    max-width: 30px;

    .form-check {
      padding: 0;
      display: inline;

      .form-check-input {
        margin: 0;
      }
    }
  }

  .td-weigth {
    max-width: 50px;
  }

  .done-style {
    text-decoration: line-through;
    color: $lightText1;
    font-size: 14px !important;
  }
  .undone-style {
    color: black;
    font-size: 16px !important;
  }
}

.ItemTab {
  h2 {
    color: $pink;
    margin-bottom: 15px;
  }
  .p3 {
    font-size: 12px;
    color: $lightText1;
  }
  .headerButtons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    flex-wrap: wrap;
    row-gap: 5px;
  }

  .alertsDiv {
    padding: 15px 0px;

    div {
      margin: 5px 0px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      column-gap: 10px;
      row-gap: 5px;
    }
  }

  .flex-space-between {
    padding: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .left {
      text-align: left;
    }

    .right {
      text-align: right;
    }
  }

  .boxDivider {
    height: 25px;
  }

  .fieldDivider {
    width: 95%;
    height: 2px;
    background-color: $lightGrey;
    margin: 25px auto;
  }

  .boxes {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    column-gap: 25px;
  }

  .customerDiv {
    width: 100%;
    textarea {
      background-color: transparent;
      text-align: center;
      border: none;
    }
  }

  .box {
    flex: 1;
    border-radius: 1rem;
    border: 2px solid $blue1;
    padding: 10px;

    display: flex;
    flex-direction: column;

    .p-title {
      font-size: 16px;
      font-weight: bold;
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 5px;
      word-break: break-all;

      flex: 1;

      p {
        white-space: pre-line;
      }
    }

    .usersDiv {
      flex: 1;
      width: 100%;
      display: flex;
      align-items: stretch;
      justify-content: center;
      column-gap: 5px;
      row-gap: 5px;
      flex-wrap: wrap;

      .user {
        border-radius: 1rem;
        border: 1px solid $darkGrey;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 5px;
        row-gap: 5px;
        flex-wrap: wrap;
        padding: 5px 8px;
      }
      p {
        font-size: 12px;
      }
    }
  }

  .fields {
    padding: 50px 10px;

    .buttons {
      margin-bottom: 25px;
    }

    .formBox {
      text-align: left;
      margin-bottom: 25px;

      .p-title {
        font-size: 18px;
        font-weight: bold;
      }
      .p-text {
        padding-left: 20px;
        padding-top: 5px;
        white-space: pre-line;
        font-size: 14px;
      }

      .form-control {
        padding-left: 20px;
        font-size: 14px;
      }
      .form-control:read-only {
        background-color: transparent;
        border: none;
      }
    }

    .answerBox {
      text-align: left;
      padding-left: 50px;
    }
  }

  .usersIn {
    display: flex;
    align-items: center;
    column-gap: 5px;
    flex-wrap: wrap;
    border: 1px solid $lightGrey;
    padding: 5px;
    border-radius: 1rem;
    margin-bottom: 15px;

    .p-title {
      color: $pink;
    }

    .user {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      column-gap: 5px;
      row-gap: 5px;
      flex-wrap: wrap;
      padding: 5px 8px;
      border-radius: 1rem;
      border: 1px solid $darkGrey;
    }
  }

  @include maxMd {
    .headerButtons {
      justify-content: center;

      a {
        flex: 1;
        white-space: nowrap;
      }
      button {
        flex: 1;
        white-space: nowrap;
      }
    }
  }

  @include maxSm {
    .boxes {
      flex-direction: column;
      row-gap: 15px;
    }
  }
}

.SimpleItemTab {
  .form-group {
    column-gap: 15px;

    .AutoSize {
      width: 100%;
    }
  }

  .form-control:disabled {
    background-color: transparent;
    border: none;
  }

  textarea:disabled {
    padding-top: 15px;
    background-color: transparent;
    border: none;
  }

  .mainHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    margin-bottom: 25px;
    border-bottom: 1px solid black;
    column-gap: 25px;

    h2 {
      word-break: break-all;
    }

    .responsibesDiv {
      display: flex;
      column-gap: 15px;
      font-size: 12px;
    }
    .responsibleSpan {
      background-color: $lightGrey;
      padding: 5px 10px;
      border-radius: 1rem;
    }
  }

  .buttonsDiv {
    width: 90%;
    margin: auto;
    display: flex;
    column-gap: 15px;
    justify-content: space-between;
    position: sticky;
    top: 116px;
    background-color: white;
    padding: 10px 0;
    border-bottom: 1px solid $lightGrey;
    z-index: 2;
  }

  .todosDiv {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 20px;
    padding: 15px;

    .myPicker {
      font-size: 12px;
      position: relative;
      text-align: right;

      .flatpickr-wrapper {
        width: 150px;
      }

      .flatpickr-input {
        width: 100%;
      }

      .clearIcon {
        color: $red;
        position: absolute;
        font-size: 22px;
        top: 5px;
        left: -25px;
      }
    }

    .todoRow {
      padding: 5px;
      border-radius: 1rem;
      border: 1px solid $darkGrey;

      p {
        margin: 0;
      }

      textarea {
        padding: 5px;
      }

      .todoRowHeader {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 10px;
        padding-left: 10px;

        .p-date {
          text-align: right;

          font-weight: bold;
        }
      }

      .div1 {
        display: flex;
        align-items: baseline;
        column-gap: 5px;
      }

      .checkDiv {
      }

      .labelDiv {
        flex: 1;
      }
      .actionsDiv {
      }

      .collapseDiv {
        background-color: $lightGrey;
        padding: 25px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap: 20px;

        .extHeader {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          row-gap: 15px;
          column-gap: 15px;
          text-align: left;
        }

        .extFooter {
          text-align: left;
        }
      }

      .addDiv {
        text-align: center;
      }
    }
    .isToday {
      color: $lime;
    }
    .isTomorrow {
      color: $darkLime;
    }
    .isWeek {
      color: $lightBlue;
    }
    .isGone {
      color: $red;
    }
  }

  .todosDiv-edit {
    grid-row-gap: 40px;

    .todoRow {
      padding: 15px 5px;
    }

    .checkDiv {
    }

    .labelDiv {
      padding: 25px;
    }
    .actionsDiv {
    }
  }

  .expandedRow {
    border: 2px solid black !important;
  }

  .otherDiv {
    width: 90%;
    margin: 25px auto;
    padding: 5px;

    display: flex;
    flex-direction: column;
    row-gap: 25px;
  }

  .addIcon {
    color: $darkLime;
    font-size: 32px !important;
  }

  @include maxMd {
    .mainHeader {
      padding: 10px 0;
      flex-direction: column-reverse;
      row-gap: 15px;
    }

    .todosDiv {
      padding: 0;
    }
  }
}

.MyItemSummary {
  p {
    margin: 0;
  }

  .circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }

  .circleToday {
    @extend .circle;
    background-color: $lime;
  }

  .circleTomorrow {
    @extend .circle;
    background-color: $darkLime;
  }

  .circleWeek {
    @extend .circle;
    background-color: $lightBlue;
  }

  .circleGone {
    @extend .circle;
    background-color: $red;
  }

  .header {
    display: flex;
    justify-content: space-between;
    text-align: left;
    padding: 15px 0;
  }

  .colorsDiv {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    row-gap: 10px;
    .colorRow {
      display: flex;
      align-items: center;
      column-gap: 10px;
    }
  }

  .filtersDiv {
    display: flex;
    flex-direction: column;
    row-gap: 5px;

    .div2 {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      column-gap: 15px;
    }
  }

  .myItemsMainDiv {
    padding: 50px 0px;
  }

  .myItemsDiv {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  .compineItems {
    grid-template-columns: repeat(1, 1fr);

    .myItemCard {
      // tässä näkymässä vain yksi kortti niin korkeus ei haittaa
      max-height: unset;
      border: none;
    }
  }

  .myItemCard {
    max-height: 450px;
    border: 1px solid $darkGrey;
    border-radius: 1rem;

    display: flex;
    flex-direction: column;

    .headerCard {
      text-align: left;
      background-color: $lightGrey;
      border-radius: 1rem 1rem 0 0;
      padding: 5px 10px;

      font-size: 22px;
      font-weight: bold;

      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 15px;

      .p-count {
        white-space: nowrap;
      }
    }

    .content {
      //  background-color: $lightBlue1;
      padding: 25px 0;
      flex: 1;
      overflow-y: auto;
    }

    .footer {
      border-radius: 0 0 1rem 1rem;
      padding: 5px;

      .responsibesDiv {
        display: flex;
        column-gap: 15px;
        font-size: 12px;
      }
      .responsibleSpan {
        background-color: $lightGrey;
        padding: 5px 10px;
        border-radius: 1rem;
      }
    }

    .todosDiv {
      display: flex;
      flex-direction: column;
    }

    .todoRow {
      padding: 10px;
      border-bottom: 1px solid $lightGrey;

      .div1 {
        display: flex;
        align-items: center;
        column-gap: 15px;
      }

      .div2 {
        text-align: left;
        padding: 25px 0;

        ul {
          padding-top: 15px;
        }
      }

      .labelDiv {
        flex: 1;
        text-align: left;

        .p-label {
          font-weight: bolder;
        }
      }

      .p-date {
        font-weight: bold;
      }
    }
    .isToday {
      color: $lime;
    }
    .isTomorrow {
      color: $darkLime;
    }
    .isWeek {
      color: $lightBlue;
    }
    .isGone {
      color: $red;
    }
  }

  @include maxMd {
    .header {
      flex-direction: column;
      padding: 0;
    }

    .filtersDiv {
      flex-direction: column-reverse;
      .div2 {
        // flex-direction: column;
        white-space: nowrap;
        align-items: flex-start;
        overflow-x: auto;
        column-gap: 25px;
        padding-bottom: 15px;
      }
    }
    .myItemsDiv {
      grid-template-columns: repeat(1, 1fr);
    }

    .myItemCard {
      .todoRow {
        padding: 25px 0;
      }
    }
  }
}

.TodoDone {
  text-decoration: line-through;
}

.TodoImportant {
  font-weight: bold;
  background-color: $lightGrey;
}
