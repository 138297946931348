.appToast {
  z-index: 9999;
  margin: auto;
  width: 100%;

  -webkit-animation: fadeIn ease-in-out;
  animation: fadeIn ease-in-out;
  -webkit-animation-duration: 0.25s;
  animation-duration: 0.25s;
}

.toast-body:hover {
  cursor: pointer;
}

strong {
  white-space: nowrap;
}

.toast-body {
  display: flex;
  flex-direction: column;
  padding: 0px 0.75rem 0.75rem 0.75rem !important;

  p {
    margin: 0;
  }

  small {
    align-self: flex-end;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}
