@import "../../variables";
@import "../../functions";

.Login {
  .container1 {
    position: fixed;
    top: 75px;
    bottom: 0px;
    left: 0px;
    right: 0px;

    display: flex;
  }

  .container1-active {
    @extend .container1;
    flex-direction: column;
    padding-bottom: 35px;
    background-color: white;

    overflow-y: auto;
  }

  .loginCards {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: stretch;
  }

  .loginCard {
    flex: 1;
    margin: 5px;

    border-radius: 1rem;

    .p-title {
      font-size: 36px;
      background-color: white;
      opacity: 0.5;

      border-radius: 1rem 1rem 0px 0px;
    }
  }
  /* .loginCard:hover {
    filter: grayscale(0);
    transition-property: filter;
    transition-duration: 1s;
  }
*/
  .companyLogin {
    background-image: url("../../images/companyLogin.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .customerLogin {
    background-image: url("../../images/customerLogin.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .consumerLogin {
    background-image: url("../../images/consumerLogin.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .loginCards-active {
    flex: 1;
  }

  form {
    flex: 1;
    background-color: white;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .loginForm {
    flex: auto;
    max-width: 750px;

    padding: 15px;

    button:focus {
      box-shadow: none;
    }
    .buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;

      a {
        color: $pink;
      }
    }

    .pwGroup {
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
    }

    .pwControl {
      border: none;
    }
  }

  .loginCard-active {
    height: 100%;

    .p-title {
      font-size: 36px;
      background-color: white;
      opacity: 0.5;
    }
  }

  .eyeIcon {
    color: $pink;
  }
  @include maxMd {
    .form-group {
      margin: 5px;
    }
    .loginCards {
      flex-direction: column;
    }

    .loginCard {
      filter: none;
    }
  }
}
