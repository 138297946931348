@import "../../variables";
@import "../../functions";

.noItems {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  background-color: white;
  margin: 50px auto;
  animation: glow 2s infinite alternate;

  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: 28px;
  }
}
