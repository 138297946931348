@import "../../variables";
@import "../../functions";

@keyframes scaleAnimation2 {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.9);
  }
}

.iconButton {
  height: 55px;
  width: 55px;
  border-radius: 50px;
  border-color: black;
  background-color: $blue2;
  z-index: 1;

  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p {
    font-size: 10px;
    margin: 0;
  }

  // shadow
  -webkit-box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.33);
  -moz-box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.33);
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.33);
  transform: scale(1);
}

.iconButtonSm {
  @extend .iconButton;
  height: 30px;
  width: 30px;
}

.iconButton:hover {
  -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 1);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 1);
  animation-name: scaleAnimation2;
  animation-duration: 0.3s;
  transform: scale(0.9);
}
/*
#actionBtn {
    position: fixed;
    bottom: 30px;
    right: 30px;
    height: 70px;
    width: 70px;
    padding: 1rem;
    border-radius: 50px;
    border-color: black;
    background-color: $blue2;
    z-index: 1;
}

#actionBtn:hover {
    border: 2px solid black;
    background-color: $lightBlue;
}

#actionBtn:focus {
    background-color: $lightBlue;
    border: 2px solid $blue2;
    -webkit-box-shadow: 0px 2px 4px 0px rgba(0,0,0,1);
    -moz-box-shadow: 0px 2px 4px 0px rgba(0,0,0,1);
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,1);
}

.hideMenu {
    background-color: $lightBlue;
    border: 2px solid $lightBlue;
    transform: scale(1);
    -webkit-box-shadow: 0px 4px 7px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 4px 7px 0px rgba(0,0,0,0.5);
    box-shadow: 0px 4px 7px 0px rgba(0,0,0,0.5);
    animation-name: scaleAnimation2;
    animation-duration: 0.3s;
    transform: scale(1);
}

.showMenu {
    background-color: #00a0b8;
    border: 2px solid #00a0b8;
    -webkit-box-shadow: 0px 2px 4px 0px rgba(0,0,0,1);
    -moz-box-shadow: 0px 2px 4px 0px rgba(0,0,0,1);
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,1);
    animation-name: scaleAnimation;
    animation-duration: 0.3s;
    transform: scale(0.9);
}*/
