@import "../../variables";

.Displays {
  .iconSuccess {
    color: $darkLime;
  }
  .iconFail {
    color: red;
  }
  p {
    margin: 0;
  }
  .dbCard {
    border: 1px solid $darkGrey;
    border-radius: 1rem;
    padding: 5px;

    .div1 {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-size: 11px;
      }
    }
    .nameDiv {
      margin-bottom: 15px;

      .p-name {
        font-size: 22px;
        color: $pink;
      }
    }

    .p-desc {
      white-space: pre-line;
      padding: 15px 0px;
    }
  }
}
