@import "../../variables";
@import "../../functions";

.BasicReport {
  background-color: white;
  min-height: calc(100vh - 245px);

  margin: 15px;
  border-radius: 1rem;
  padding: 15px 15px 35px 15px;

  h1 {
    color: black;
  }
}
.OneCustomerReport  {
  .form-control:read-only {
    background-color: transparent;
  }
  .p-title {
    text-align: left;
    font-size: 28px;
    color: $lightBlue;
  }

  .p-name {
    font-size: 22px;
    font-weight: bolder;
    margin: 15px 0px 5px 0px;
  }

  .header {
    display: flex;
    align-items: center;
    margin: 15px 0px;
  }

  .divider {
    flex-grow: 1;
    border-bottom: 2px solid $lightBlue;
    margin: 25px;
  }

  p {
    text-align: left;
    font-size: 18px;
    color: $darkGrey;
    margin: 0;
  }
  .item {
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .prices {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @include maxSm {
    .prices {
      flex-direction: column;
      p {
        margin-bottom: 5px;
      }
    }
  }
}

.OneCompanyreport {
  max-width: 1200px;
  background-color: white;
  margin: 15px auto;
  padding: 15px;
  border-radius: 1rem;

  h1 {
    color: $darkGrey;
  }

  .titleHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .switchDiv {
      justify-content: space-between;
    }
  }
  .buttons {
    padding: 15px 0px;
    position: sticky;
    top: 75px;
    right: 0px;
    background-color: white;
    z-index: 999;

    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $darkGrey;
    margin: 0px -15px 0px -15px;
    padding: 15px;
  }

  .header {
    .dates {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .space {
    height: 25px;
    border-top: 5px solid $lightPink;
  }

  .summary {
    margin-top: 25px;
    .prices {
      padding: 15px 0px;
      .div1 {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
      }
    }

    .hours {
      padding: 15px 0px;
      .div1 {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
      }
    }
  }

  @include maxMd {
    margin: 10px;
    padding: 10px;
    .summary {
      .div1 {
        flex-direction: column;
      }
    }

    .buttons {
      margin: 0px -5px 0px -5px;
      padding: 5px;

      top: 56px;
    }
  }

  @include maxSm {
    margin: 5px;
    padding: 5px;

    .header {
      .dates {
        flex-direction: column;
        p {
          margin: 0;
        }
      }
    }

    .buttons {
      top: 42px;
    }
  }
}
