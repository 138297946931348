@import "../../variables";
@import "../../functions";

.ChatRooms {
  .titleRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    div {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  .roomsDiv {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  .roomsDivList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 25px;
    max-width: 750px;
    margin: auto;
  }

  .deleteIcon {
    color: $pink;
    margin-right: 10px;
    font-size: 22px;
  }

  .editIcon {
    color: $darkGrey;
    font-size: 22px;
  }
  .roomCard {
    padding: 5px;

    p {
      margin: 0;
    }

    .header {
      margin: -5px -5px 0px -5px;
      padding: 10px;

      border-bottom: 1px solid $lightGrey;

      .p-title {
        font-size: 22px;
        font-weight: bolder;
        color: $pink;
        text-align: center;
      }

      .buttons {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        padding-bottom: 10px;

        div {
          display: flex;
          column-gap: 15px;
        }
      }
    }

    .msg {
      padding: 10px 0px;
      p {
        text-align: left;
        margin: 0;
      }

      .p-msg-date {
        color: $lightText1;
        font-size: 11px;
        padding-left: 5px;
      }
      .p-author {
        color: $lightText1;
        font-size: 11px;
        padding-left: 5px;
      }

      .info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 3px 0px;
      }

      textarea {
        background-color: transparent;
      }
    }

    .footer {
      padding: 5px 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 15px;
      border-top: 1px solid $lightGrey;
    }
  }

  .roomCard:hover {
    cursor: pointer;
    transform: scale(1.02);
    -webkit-transition: transform 0.2s ease-out !important;
    -moz-transition: transform 0.2s ease-out !important;
    -o-transition: transform 0.2s ease-out !important;
    transition: transform 0.2s ease-out !important;
  }

  @include maxSm {
    .roomsDiv {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.OneRoom {
  max-width: 1200px;
  margin: 15px auto;
  padding-bottom: 200px;
  p {
    margin: 0;
    text-align: left;
  }

  .div1 {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      color: $lightText1;
    }
  }

  .newMsgDiv {
    width: 90%;
    max-width: 650px;
    margin: auto;

    textarea {
      border-radius: 1rem;
    }
  }

  .roomInfo {
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    p {
      font-size: 12px;
    }
    textarea {
      font-size: 12px;
    }

    .inRoom {
      padding: 15px 0px;
      p {
        color: $lightText1;
      }
    }

    div {
      flex: 1;
    }

    textarea {
      background-color: transparent;
      text-align: right;
      border: none;
    }
  }

  .p-noMsg {
    text-align: center;
  }
  .messages {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 15px;

    hr {
      width: 80%;
      border-color: $pink;
      padding: 15px;
    }
  }
  .msgBox {
    max-width: 80%;

    border: 1px solid $pink;
    border-radius: 1rem;
    padding: 5px 10px;
    min-width: 150px;

    .header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      column-gap: 15px;
      .p-author {
        color: $lightText1;
        font-size: 11px;
      }

      .star2 {
        color: $lightGrey;
        font-size: 14px;
      }

      .star1 {
        color: $gold;
        font-size: 14px;
      }
    }

    .content {
      padding: 5px 0px;
    }

    .p-msg {
      white-space: pre-line;
    }
  }

  .myGroupStyle {
    border: 1px solid $darkGrey;
    align-self: flex-end;
  }

  .myMsgStyle {
    align-self: flex-end;
    border: 1px solid $lightBlue;
  }

  .newForMeStyle {
    border: 2px solid $lime;
  }

  .infoMsg {
    background-color: $lightGrey;
    align-self: center;
    margin: 25px 0px;
    width: 100%;
    border: none;
    p {
      text-align: center !important;
    }

    .header {
      display: block;
    }
  }

  .readByDiv {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    row-gap: 10px;
    margin-top: 10px;
    border-top: 1px solid $darkGrey;

    p {
      font-size: 12px;
    }

    .p-date {
      color: $lightText1;
    }
    .p-name {
      font-weight: bolder;
    }
  }

  @include maxSm {
    .msgBox {
      max-width: 95%;
    }
  }
}

.NoRoom {
  .box {
    border: 1px solid $pink;
    max-width: 750px;
    border-radius: 1rem;
    margin: 50px auto;
    padding: 20px;
  }
}
.popover-body {
  background-color: $lightGrey !important;
  p {
    margin: 0;
    color: black !important;
  }
}
