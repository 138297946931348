@import "variables";
@import "functions";

$primary: $lightBlue;
$danger: $status1;
$warning: $status2;
$success: $status4;
$info: $blue2;

@import "~bootstrap/scss/bootstrap";

/*::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: $darkGrey;
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb {
  background: $blue2;
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb:hover {
  background: $darkLime;
}*/

.badge {
  white-space: pre-wrap;
  word-break: break-all;
}

.badge-danger {
  color: white;
  animation: scaleAnimationBtn 2s infinite alternate;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeinout {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px $lime;
  }
  to {
    box-shadow: 0 0 10px 10px $darkLime;
  }
}

@keyframes scaleAnimation {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.97);
  }
}

@keyframes scaleAnimationBtn {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.8);
  }
}

.scale2 {
  animation: scaleAnimation 3s infinite alternate;
}

.fadeInOut {
  animation: fadeinout 2s linear 1 forwards;
  animation-iteration-count: infinite;
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.PageContent {
  position: fixed;
  top: 75px;
  bottom: 0px;
  right: 0px;
  left: 0px;

  overflow-y: scroll;
}
.boxShadow {
  -webkit-box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.33);
  -moz-box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.33);
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.33);
  transform: scale(1);
  border-radius: 1rem;
}

.smallShadow {
  -webkit-box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.33);
  -moz-box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.33);
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.33);
  transform: scale(1);
  border-radius: 1rem;
}

.smallShadow2 {
  -webkit-box-shadow: 2px 2px 2px 2px rgba(255, 255, 255, 0.33);
  -moz-box-shadow: 2px 2px 2px 2px rgba(255, 255, 255, 0.33);
  box-shadow: 2px 2px 2px 2px rgba(255, 255, 255, 0.33);
  transform: scale(1);
  border-radius: 1rem;
}

.formShadow {
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.33);
  transform: scale(1);
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both;
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

body {
  margin: 0;
  font-family: "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  display: flex;
  flex-direction: column;
}

h1 {
  color: $blue1;
}

p {
  color: $black;
}

.alertContainer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 9999;
}

.toastContainer {
  position: fixed;
  top: 104px;
  right: 16px;
  width: 100%;
  max-width: 370px;
  z-index: 9999;
}

.versionNumber {
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 12px 40px 10px 10px;
  font-size: 12px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.666);
  border-top-right-radius: 5px;
}

.wsConnection {
  position: fixed;
  bottom: 0;
  left: 35px;
  margin: 10px;
  z-index: 1;

  p {
    display: inline-flex;
    font-size: 12px;
    margin-left: 5px;
    margin-bottom: 0;
    color: #555555;
  }
}

.disabled-link {
  pointer-events: none;

  color: $lightGrey !important;
}

#installButton {
  position: fixed;
  top: 5px;
  left: 5px;
  z-index: 9999;
  font-size: 12px;
}

.linkBtn:hover {
  text-decoration: none;
}

.titleDecoration {
  width: 95px;
  height: 5px;
  background-color: $pink;
  border-radius: 1rem;
  margin: auto;
}

.primaryBtn {
  font-size: 18px;
  border-radius: 1rem !important;
  background-color: $pink;
  border: none !important;
  color: white;
  // shadow
  // shadow
  -webkit-box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.33);
  -moz-box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.33);
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.33);
  transform: scale(1);
  border-radius: 1rem;
}

.primaryBtn:disabled {
  background-color: $lightPink !important;
  color: $darkGrey !important;
  // shadow
  text-decoration: none;
}

.primaryBtn:hover:not(:disabled) {
  background-color: $pink !important;
  cursor: pointer;
  transform: scale(1.02);
  -webkit-transition: transform 0.2s ease-out !important;
  -moz-transition: transform 0.2s ease-out !important;
  -o-transition: transform 0.2s ease-out !important;
  transition: transform 0.2s ease-out !important;
  color: $darkGrey;
}
.primaryBtn:active {
  background-color: $pink !important;
}
.primaryBtn:focus {
  background-color: $pink !important;

  -webkit-box-shadow: 1px 1px 1px 0px $dropShadow !important;
  -moz-box-shadow: 1px 1px 1px 0px $dropShadow !important;
  box-shadow: 0px 0px 0px 0.2rem $dropShadow !important;
}

.secondaryBtn {
  font-size: 18px;
  border-radius: 1rem !important;
  background-color: $lightBlue1 !important;
  border: none !important;
  color: black !important;
  margin: 5px 0px !important;

  // shadow
  -webkit-box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.33);
  -moz-box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.33);
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.33);
  transform: scale(1);
  border-radius: 1rem;
}

.secondaryBtn:disabled {
  background-color: $lightGrey;
  color: $white;
}
 
.secondaryBtn:hover:not(:disabled) {
  cursor: pointer;
  transform: scale(1.02);
  color: $white;
  background-color: $blue1 !important;
  -webkit-transition: transform 0.2s ease-out !important;
  -moz-transition: transform 0.2s ease-out !important;
  -o-transition: transform 0.2s ease-out !important;
  transition: transform 0.2s ease-out !important;
}
.secondaryBtn:hover:disabled {
  background-color: $lightGrey;
}

.secondaryBtn:focus {
  -webkit-box-shadow: 1px 1px 1px 0px $dropShadow !important;
  -moz-box-shadow: 1px 1px 1px 0px $dropShadow !important;
  box-shadow: 0px 0px 0px 0.2rem $dropShadow !important;
}

.deleteBtn {
  font-size: 18px;
  border-radius: 4px !important;
  background-color: $red;
  border: none !important;
  color: black;
  // shadow
  -webkit-box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.33);
  -moz-box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.33);
  box-shadow: 1px 1px 1px 1px $dropShadow;
  margin: 5px 0px !important;
}

.deleteBtn:disabled {
  background-color: $red !important;
  color: $darkGrey !important;
  // shadow
  text-decoration: none;
}

.deleteBtn:hover:not(:disabled) {
  background-color: red !important;
  color: white;
  cursor: pointer;
  transform: scale(1.02);
  -webkit-transition: transform 0.2s ease-out !important;
  -moz-transition: transform 0.2s ease-out !important;
  -o-transition: transform 0.2s ease-out !important;
  transition: transform 0.2s ease-out !important;
}

.fileForm {
  padding: 20px;
  background-color: transparent !important;
  .formButtons {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 25px;
  }

  .custom-file {
    margin: 5px auto;
  }

  .custom-file-label {
    @extend .secondaryBtn;

    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 90%;
  }

  .custom-file-label:focus {
    outline: none !important;
  }

  .custom-file-label::after {
    display: none;
  }

  .form-control {
    background-color: $white !important;
  }

  .form-control:disabled {
    background-color: $lightGrey !important;
  }

  .form-group {
    margin-top: 25px;
  }

  .form-label {
    font-size: 18px;
  }

  .selectedRow {
    align-items: center;
    justify-content: center;

    p {
      font-size: 18px;
      margin: 0px;
    }
  }
}

.p-title1 {
  font-size: 22px;
  font-weight: 500;
}

.appName {
  display: flex;
  align-items: baseline;

  .form-group {
    flex: 1;
  }
}

.appContact {
  display: flex;
  align-items: baseline;
  margin: 25px 0px;

  .form-group {
    flex: 1;
  }
}

.appAddress {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  .form-group {
    flex: 1;
  }
}

.form-control:focus {
  border-color: $blue1;
  box-shadow: 0 0 0 0.2rem $lightBlue1;
}
.basicPage {
  background-color: white;
  margin: 15px 15px 0 15px;
  min-height: calc(100vh - 105px);
  border-radius: 1rem 1rem 0 0;
  padding: 15px;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ellipsis:after {
  content: "\0000a0";
  display: inline-block;
  width: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: white;
  border-color: $darkGrey;
}
.custom-control-input:checked ~ .custom-control-label::after {
  background-color: $darkLime !important;
}

.custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: $darkGrey;
}
.lockBox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 5px;
  padding-bottom: 10px;

  p {
    font-size: 18px;
    color: $pink;
    margin: 0;
  }
}

@include maxSm {
  .appName {
    flex-direction: column;
  }

  .appContact {
    flex-direction: column;
  }

  .appAddress {
    flex-direction: column;
  }

  .basicPage {
    margin: 5px 5px 0 5px;
    padding: 5px 5px 25px 5px;
    min-height: calc(100vh - 85px);
  }
}

.fullSizeModal {
  .modal-content {
    height: 100vh;
  }
}

.Width-modal-90 {
  width: 90%;
  max-width: unset !important;
  margin: auto;
}

.fullScreenModal {
  .modal {
    padding: 0 !important; // override inline padding-right added from js
  }
  .modal-dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-body {
    overflow-y: auto;
  }
}

.outLine-pink {
  padding: 5px 15px;
  border: 2px solid $pink;
  border-radius: 1rem;

  color: black;
}

.outLine-pink:hover {
  background-color: $lightPink;
  color: $darkGrey;
  text-decoration: none !important;
}

.outLine-blue {
  padding: 5px 15px;
  border: 2px solid $status3;
  border-radius: 1rem;

  color: black;
}

.outLine-blue:hover {
  background-color: $lightBlue1;
  color: $darkGrey;
  text-decoration: none !important;
}

.outLine-grey {
  padding: 5px 15px;
  border: 2px solid $darkGrey;
  border-radius: 1rem;

  color: $darkGrey;
}

.outLine-grey:hover {
  background-color: $lightGrey;
  color: $darkGrey;
  text-decoration: none !important;
}

.outLine-lime {
  padding: 5px 15px;
  border: 2px solid $lime;
  border-radius: 1rem;

  color: $darkGrey;
}

.outLine-lime:hover {
  background-color: $darkLime;
  color: $darkGrey;
  text-decoration: none !important;
}

@include maxXs {
  #installButton {
    top: 0px;
    left: 0px;
  }
}

.appImgCollapseDiv {
  border: 1px solid $darkGrey;
  margin: 15px 0px;
  border-radius: 1rem;
  padding: 15px 0px;

  .appInfoText {
    padding: 25px 0px;
  }
}
.appImagesDiv {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  padding: 0px 10px;

  .appButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .appImgDiv {
    padding: 10px;

    img {
      max-width: 100%;
      max-height: 300px;
    }

    video {
      //max-height: 250px;
      max-width: 100%;
    }
  }

  @include maxSm {
    grid-template-columns: repeat(1, 1fr);
  }
}

.link-user-name {
  position: fixed;
  top: 0px;
  left: 5px;
  z-index: 99999;
  white-space: nowrap;
  font-size: 12px;
}

.form-control:focus {
  box-shadow: none;
  border-color: $darkGrey;
}
