// Värit
$black: #0f1416;
$white: #fff;
$dropShadow: #dfe8ec; //vaalean siniharmaa

$lightGrey: #eceff1; // vaalean lämmin harmaa
$lightGrey2: #d0d7dc;
// Määrittelemättömät
$green: #b9f6ca;
$yellow: #fff59d;
$red: #ef9a9a;

$lightBlue: #00b8d4; // päätehoste väri

$lime: #d5f42a;
$darkLime: #96ae09;

$blue2: #3289ca;

$pink: #ff1493;
$lightPink: #ffadd9;
$lightPinkBack: #ffebf5;

$blue1: #69ddff;
$lightBlue1: #c2f1ff;
$lightBlueBack: #ebfaff;

$border1: #b6169b;
$border2: #008db8;

$gold: #d4af37;

$darkGrey: #464543;

$brightYellow: #fff15c;
$orange: #ef9606;

// responsive layout sizes
$xs: 330px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

$h4: 25px;

$titleBack: #464543;

$lightText1: #7e92a0;

$status0: #C59EE6;
$status1: #F0A3A7 ;
$status2: #F0F085 ;
$status3: #86BFEA ;
$status4: #86D6B6 ;
$status5: #464543 ;