@import "../../../variables";
@import "../../../functions";

.Select {
  padding: 15px 0px;

  .selectTitle {
    h3 {
      margin: 0;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0px;
    margin-bottom: 10px;

    .select {
      flex: 1;
      margin-left: 15px;
      z-index: 10;
    }
  }

  .divider {
    flex-grow: 1;
    border-bottom: 2px solid $lightBlue;
    margin: 25px;
  }

  .partsTable {
    p {
      margin-bottom: 15px;
      font-size: 22px;
    }
  }

  @include maxSm {
    .divider {
      display: none;
    }
  }
}

.Select2 {
  .number-1 {
    z-index: 99;
  }

  .number-2 {
    z-index: 9;
  }

  .selectSwitches {
    margin-bottom: 5px;
  }
}

.activeSelect {
  border: 2px solid $lime;
  border-radius: 0.5rem;
}