@import "../../variables";
@import "../../functions";

.RecordAudio {
  border: 1px solid $darkGrey;

  .controls {
    .time {
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        font-size: 22px;
        margin: 0px 10px 0px 0px;
      }
    }

    .div2 {
      padding: 22px 0px;
    }
  }

  .audioDiv {
    max-width: 650px;
    margin: auto;
    padding: 12px 50px;

    audio {
      max-width: 100%;
    }
  }
  .iconMic {
    color: $pink;
    border: none;
    border-radius: 50%;
    background-color: $lightPink;
    cursor: pointer;

    width: 72px;
    height: 72px;

    padding: 20px;
  }
  .iconMic:hover {
    background-color: $pink;
    color: $lightPink;
  }

  .iconStop {
    color: $pink;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    width: 72px;
    height: 72px;

    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }

  .iconStop:hover {
    background-color: $pink;
    color: $lightPink;
  }

  @include maxMd {
    .audioDiv {
      margin: auto;
      padding: 12px;
    }
  }
}

.text-warning {
  color: $pink !important;
}

.SelectFile2 {
  padding: 22px 10px;
  border: 1px solid $pink;
  border-radius: 1rem;
  margin-bottom: 50px;

  .custom-file-label::after {
    display: none;
  }

  .custom-file-label {
    text-align: center;
    border-radius: 1rem;
    color: $pink;

    -webkit-box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.33);
    -moz-box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.33);
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.33);
  }

  .custom-file:hover {
    border-radius: 1rem;

    -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 1);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 1);
    animation-name: scaleAnimation2;
    animation-duration: 0.3s;
    transform: scale(0.98);
  }
}

.fileForm {
  background-color: $lightGrey !important;
  .categoryDiv {
    display: flex;
    column-gap: 15px;
    margin-bottom: 25px;
  }
}
