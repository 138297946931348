// Muuttujat (koot) tulee _variables.scss tiedostosta

//valitut tyylit tulee voimaan määritetyn koon alapuolella
@mixin maxXs {
    @media (max-width: #{$xs}) {
        @content;
    }
}
@mixin maxSm {
    @media (max-width: #{$sm}) {
        @content;
    }
}
@mixin maxMd {
    @media (max-width: #{$md}) {
        @content;
    }
}
@mixin maxLg {
    @media (max-width: #{$lg}) {
        @content;
    }
}
@mixin maxXl {
    @media (max-width: #{$xl}) {
        @content;
    }
}

// valitut tyylit tulee voimaan määritetyn koon yläpuolella

@mixin minSm {
    @media (min-width: #{$sm}) {
        @content;
    }
}

@mixin minMd {
    @media (min-width: #{$md}) {
        @content;
    }
}

@mixin minLg {
    @media (min-width: #{$lg}) {
        @content;
    }
}
@mixin minXl {
    @media (min-width: #{$xl}) {
        @content;
    }
}